import styled from '@emotion/styled';

const StyledLayout = styled.div`
  .loader {
    position: fixed;
    z-index: 99;
  }
  
  // Footer overrides
  .footer__nav-list {
    gap: unset;
    // Qualtrics Footer Override
    .QSILink{
      color: #707070;
    }
    .QSILink:hover{
      color: #111;
      border-bottom: 1px solid #111;
    }
  }
`;

export default StyledLayout;
