import { FooterUrlRoutes } from '../../../constants';

const footerLinks = [
  {
    dataTestId: 'About_Regions_Footer_Link',
    hrefPath: FooterUrlRoutes.AboutRegionsFooterUrlRoute,
    linkText: 'About Regions',
    target: '_blank',
  },
  {
    dataTestId: 'Investor_Relations_Footer_Link',
    hrefPath: FooterUrlRoutes.InvestorRelationsFooterUrlRoute,
    linkText: 'Investor Relations',
    target: '_blank',
  },
  {
    dataTestId: 'Privacy_And_Security_Footer_Link',
    hrefPath: FooterUrlRoutes.PrivacyAndSecurityFooterUrlRoute,
    linkText: 'Privacy & Security',
    target: '_blank',
  },
  {
    dataTestId: 'Website_Terms_Of_Use_Footer_Link',
    hrefPath: FooterUrlRoutes.WebsiteTermsAndConditionsFooterUrlRoute,
    linkText: 'Website Terms of Use',
    target: '_blank',
  },
  {
    dataTestId: 'Online_Tracking_And_Advertising_Footer_Link',
    hrefPath: FooterUrlRoutes.OnlineTrackingAndAdvertisingFooterUrlRoute,
    linkText: 'Online Tracking & Advertising',
    target: '_blank',
  },
  {
    dataTestId: 'Accessible_Banking_Footer_Link',
    hrefPath: FooterUrlRoutes.AccessibleBankingFooterUrlRoute,
    linkText: 'Accessible Banking',
    target: '_blank',
  },
  {
    id: 'dlo_qualtrics_footer_feedback_link',
    dataTestId: 'DLO_Leave_Feedback_Footer_Link',
  },
];

export default footerLinks;
