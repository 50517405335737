import { UIEnvironmentRoutes, APIGatewayEnvironmentRoutes } from '../constants';

/* eslint no-console: 0 */
export const envs = {
  fallback: APIGatewayEnvironmentRoutes.APIGatewayDevURL,
  // dev
  [UIEnvironmentRoutes.UIDevURL]: APIGatewayEnvironmentRoutes.APIGatewayDevURL,
  [UIEnvironmentRoutes.UIDevVanityURL]: APIGatewayEnvironmentRoutes.APIGatewayDevURL,
  [UIEnvironmentRoutes.UIQAURL]: APIGatewayEnvironmentRoutes.APIGatewayQAURL,
  [UIEnvironmentRoutes.UIQAVanityURL]: APIGatewayEnvironmentRoutes.APIGatewayQAURL,
  [UIEnvironmentRoutes.UITestURL]: APIGatewayEnvironmentRoutes.APIGatewayTestURL,
  [UIEnvironmentRoutes.UITestVanityURL]: APIGatewayEnvironmentRoutes.APIGatewayTestURL,
  [UIEnvironmentRoutes.UIProductionVanityURL]: APIGatewayEnvironmentRoutes.APIGatewayProductionURL,
};

export const setApiUrl: () => string = () => {
  const { hostname } = window.location;
  let apiUrl;
  if (!(hostname in envs)) {
    apiUrl = envs.fallback;
  } else {
    // @ts-ignore
    apiUrl = envs[hostname];
  }

  return apiUrl;
};

export const baseUrl = () => `${setApiUrl()}`;
export const url = (api: string) => `${baseUrl()}/${api}`;
